export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
    token?: string;
};

export type SimpleChatRequest = {
    question: string;
    token?: string;
};


export type CCSChatRequest = {
    question: string;
    segment: string;
    token?: string;
};

export type AuthRequest = {    
    token?: string;
};

export type AudienceVariablesRequest = {
    profile: string;
    token?: string;
};

export type AudienceVariablesResponse = {
    variables: AudienceVariable[];    
};

export type AudienceVariable = {
    Label: string;
    VariableName: string;
    Reasoning: string;
}