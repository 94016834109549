import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
// import LoginCallback from './LoginCallback';
import { RequiredAuth } from './SecureRoute';
import Loading from './Loading';
// import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import SimpleChat from "./pages/chat/SimpleChat";
import AudienceChat from "./pages/chat/AudienceChat";
import AssociationChat from "./pages/chat/AssociationChat";
const oktaIsser = import.meta.env.VITE_OKTA_ISSUER;
const oktaClientId = import.meta.env.VITE_OKTA_CLIENT_ID;

initializeIcons();
const oktaAuth = new OktaAuth({
    issuer: oktaIsser,
    clientId: oktaClientId,
    redirectUri: window.location.origin + '/login/callback'
  });
export default function App() {
    const navigate = useNavigate();
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        console.log('restoreOriginalUri', originalUri)
        console.log('window.location.origin', window.location.origin)
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    return (
        // <HashRouter>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
               <Route path='/' element={<Home />} />
               <Route path='login/callback' element={<LoginCallback loadingElement={<Loading />} />} />
                {/* <Route path="/login/callback" element={<Navigate to="/home" />} /> */}
                <Route path='/home' element={<RequiredAuth />}>
                    {/* <Route path='' element={<Protected />} /> */}
                    <Route path="/home" element={<Layout />}>
                    <Route index element={<Chat />} />
                    <Route path="/home/simplechat" element={<SimpleChat />} />
                    <Route path="/home/associationchat" element={<AssociationChat />} />
                    <Route path="/home/audiencechat" element={<AudienceChat />} />
                     {/* <Route path="qa" element={<OneShot />} /> */}
                    <Route path="*" element={<NoPage />} /></Route>
                </Route>
            </Routes>
            </Security>
            
        // </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
         <App />
        </BrowserRouter>
    </React.StrictMode>
);
