import { useMemo } from "react";
import { Stack, Button, IconButton } from "@fluentui/react";
import {
    PresenceBadgeStatus,
    Avatar,
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
  } from "@fluentui/react-components";

import DOMPurify from "dompurify";

import styles from "./AudienceVariables.module.css";

import { AudienceVariable, getCitationFilePath } from "../../api";

import { AudienceVariablesIcon } from "./AudienceVariablesIcon";

interface Props {
    variables: AudienceVariable[];
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const AudienceVariables = ({
    variables,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
        
    const columns: TableColumnDefinition<AudienceVariable>[] = [
        createTableColumn<AudienceVariable>({
            columnId: "variable",
            compare: (a, b) => {
              return a.VariableName.localeCompare(b.VariableName);
            },
            renderHeaderCell: () => {
              return "Variable";
            },
            renderCell: (item) => {
              return (
                <TableCellLayout>
                  {item.VariableName}
                </TableCellLayout>
              );
            },
          }),
          createTableColumn<AudienceVariable>({
            columnId: "label",
            compare: (a, b) => {
              return a.Label.localeCompare(b.Label);
            },
            renderHeaderCell: () => {
              return "Label";
            },
            renderCell: (item) => {
              return (
                <TableCellLayout>
                  {item.Label}
                </TableCellLayout>
              );
            },
          }),
          createTableColumn<AudienceVariable>({
            columnId: "reasoning",
            compare: (a, b) => {
              return a.Reasoning.localeCompare(b.Reasoning);
            },
            renderHeaderCell: () => {
              return "Reasoning";
            },
            renderCell: (item) => {
              return (
                <TableCellLayout>
                  {item.Reasoning}
                </TableCellLayout>
              );
            },
          }),
    ];
    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AudienceVariablesIcon />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={true} // no thoughts
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={true} // no thoughts
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
            <DataGrid
                items={variables}
                columns={columns}
                sortable
                selectionMode="multiselect"
                getRowId={(item) => item.VariableName}
                onSelectionChange={(e, data) => console.log(data)}
            >
                <DataGridHeader>
                    <DataGridRow selectionCell={{ "aria-label": "Select all rows" }}>
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                    )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<AudienceVariable>>
                    {({ item, rowId }) => (
                    <DataGridRow<AudienceVariable>
                        key={rowId}
                        selectionCell={{ "aria-label": "Select row" }}
                    >
                        {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                        )}
                    </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
            <Button className={styles.extraActionButton}>Analyse audience</Button>
            </Stack.Item>           
        </Stack>
    );
};
