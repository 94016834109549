import { AskRequest, AskResponse, ChatRequest, CCSChatRequest, SimpleChatRequest, AuthRequest, AudienceVariablesRequest, AudienceVariablesResponse } from "./models";

const API_URL = import.meta.env.VITE_BACKEND_URI;
const NEW_API_URL = import.meta.env.VITE_API_URL;

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch(`${API_URL}/ask`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorisation": "Bearer"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function simpleChatApi(options: SimpleChatRequest): Promise<AskResponse> {
    const response = await fetch(`${NEW_API_URL}/chat`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${options.token}`
        },
        body: JSON.stringify({
            question: options.question,
        })
    });

    const parsedResponse: AskResponse = await response.json();
    parsedResponse.data_points = [];
    
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function ccsChatApi(options: CCSChatRequest): Promise<AskResponse> {
    const response = await fetch(`${NEW_API_URL}/ccs/chat`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${options.token}`
        },
        body: JSON.stringify({
            question: options.question,
            segment_name: options.segment
        })
    });

    const parsedResponse: AskResponse = await response.json();
    parsedResponse.data_points = [];
    
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function audienceVariables(options: AudienceVariablesRequest): Promise<AudienceVariablesResponse> {
    const response = await fetch(`${NEW_API_URL}/ccs/variables`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${options.token}`
        },
        body: JSON.stringify({
            profile: options.profile,
        })
    });

    const parsedResponse: AudienceVariablesResponse = await response.json();        
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function clearSimpleChatApi(options: AuthRequest): Promise<AskResponse> {
    const response = await fetch(`${NEW_API_URL}/memory`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${options.token}`
        }        
    });

    const parsedResponse = await response.json();        
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch(`${API_URL}/chat`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${options.token}`
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}