import { AssociationChatExample } from "./AssociationChatExample";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "What associations are there between electric vehicles and other topics? Show the results as a list",
        value: "What associations are there between electric vehicles and other topics? Show the results as a list"
    },
    { 
        text: "Invent a persona for someone who would is interested in online gaming and cars based on the associations you see",
        value: "Invent a persona for someone who would is interested in online gaming and cars based on the associations you see"
    },
    { 
        text: "What insights can you draw from people who cited the Audi brand?", 
        value: "What insights can you draw from people who cited the Audi brand?" 
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const AssociationChatExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <AssociationChatExample text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
