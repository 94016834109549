import { AudienceChatExample } from "./AudienceChatExample";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "What sort of person would be interested in buying an electric car?",
        value: "What sort of person would be interested in buying an electric car?"
    },
    { 
        text: "Write a profile of the type of person who would be interested in fine dining that could be used to target them with advertising",
        value: "Write a profile of the type of person who would be interested in fine dining that could be used to target them with advertising"
    },
    { 
        text: "Invent a persona for someone interested in buying an electric car, give them a name, an age and occupation", 
        value: "Invent a persona for someone interested in buying an electric car, give them a name, an age and occupation" 
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const AudienceChatExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <AudienceChatExample text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
